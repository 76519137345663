import React from 'react';
import BirthDateInput from './BirthDateInput';
import PhoneNumberInput from './PhoneNumberInput';
import EmailInput from './EmailInput';
import AddressInput from './AddressInput';
import { ProfileDataType, ProfileField } from '../../../types/profile';
import BusinessNameInput from './BusinessNameInput';

interface ProfileFieldProps {
  field: ProfileField;
  setField: (field: ProfileField) => void;
  canBeEmpty?: boolean;
  emailCheck?: boolean;
  setAccountExists?: (exists: boolean) => void;
  mandatory?: boolean;
  label?: boolean;
  validate?: boolean
}
/**
 * Renders a single profile field in a list of fields.
 *
 * @param {Object} props - The component props.
 * @param {ProfileField} props.field - The profile field to render.
 * @param {Function} props.setModalData - The function to call when the edit or delete button is clicked.
 * @returns {JSX.Element} The rendered profile field.
 */
export default function ProfileFieldForm({
  field,
  setField,
  canBeEmpty = false,
  emailCheck = false,
  mandatory = false,
  label = false,
  validate=false,
  setAccountExists = () => {},
}: ProfileFieldProps): JSX.Element {
  if (!field) return <div />;
  switch (field.dataType) {
    case ProfileDataType.EMAIL:
      return (
        <EmailInput
          field={field}
          setField={setField}
          canBeEmpty={canBeEmpty}
          emailCheck={emailCheck}
          setAccountExists={setAccountExists}
          mandatory={mandatory}
          label={label}
          validate={validate}
        />
      );
    case ProfileDataType.ADDRESS:
      if (!field.address) return <div />;
      return (
        <AddressInput
          field={field}
          setField={setField}
          canBeEmpty={canBeEmpty}
          mandatory={mandatory}
          label={label}
          validate={validate}
        />
      );
    case ProfileDataType.PHONENUMBER:
      if (!field.phoneNumber) return <div />;
      return (
        <PhoneNumberInput
          field={field}
          setField={setField}
          canBeEmpty={canBeEmpty}
          mandatory={mandatory}
          label={label}
          validate={validate}
        />
      );
    case ProfileDataType.BIRTHDATE:
      return (
        <BirthDateInput
          field={field}
          setField={setField}
          canBeEmpty={canBeEmpty}
          mandatory={mandatory}
          label={label}
          validate={validate}
        />
      );
    case ProfileDataType.BUSINESSNAME:
      return (
        <BusinessNameInput
          field={field}
          setField={setField}
          canBeEmpty={canBeEmpty}
          mandatory={mandatory}
          label={label}
        />
      );
    default:
      return <div />;
  }
}
