import React from 'react';

export default function PrivacyText(): JSX.Element {
  return (
    <>
      {' '}
      <h1 className="text-[28px] font-semibold">Gebruikersvoorwaarden en privacy</h1>
      {/* set back 'md:w-2/3' when not in modal anymore */}
      <p className="">
        Met Vera Connect kan je snel, eenvoudig en veilig contactgegevens uitwisselen zonder
        controle uit handen te geven. In dit document geven we duidelijk en transparant aan hoe we
        dit doen en wat je van ons kunt verwachten. Jouw privacy is belangrijk voor Vera Connect. We
        beschrijven dan ook uitgebreid welke persoonsgegevens wij van jou verzamelen, waarom we deze
        verwerken, waar we deze informatie voor gebruiken en hoe jij deze informatie kan beheren.
      </p>
      <p className=" mt-3">
        <b>Wat is Vera Connect?</b>
        <br />
        Vera Connect is een applicatie waarmee persoonlijke gegevens zoals je (bedrijfs)naam,
        telefoonnummer, adres, e-mailadres, geboortedatum, trouwdatum, namen van je kinderen en hun
        verjaardag kunnen worden uitgewisseld. Je nodigt eenvoudig iemand uit om zijn gegevens met
        jou te delen. De ontvanger krijgt bericht en kan kiezen of hij/zij de gegevens invult in
        Vera Connect en met je deelt.
      </p>
      <p className=" mt-3">
        <b>Hoe werkt Vera Connect?</b>
        <br />
        In tegenstelling tot andere contactenlijsten of adressenboeken is iedereen de beheerder van
        hun eigen persoonsgegevens. Hierin kan je te allen tijde je persoonsgegevens beheren,
        verwijderen en aanpassen. Je maakt dan deel uit van het Vera Connect platform en kunt daar
        optimaal gebruik van maken. Door connecties te maken kunnen Vera Connect gebruikers elkaar
        toegang geven tot specifieke gegevens.
        <br className="mb-4" />
        Wanneer jij toegang hebt tot specifieke gegevens van een andere gebruiker (en vice versa)
        kan jij de gegevens zien en gebruiken, maar deze niet aanpassen. Gedurende de periode dat
        jij anderen toegang geeft tot jouw gegevens kunnen zij die gebruiken. Zij kunnen jou
        bijvoorbeeld bellen, mailen of een kaartje voor je verjaardag sturen.
      </p>
      <p className=" mt-3">
        <b>Toegang tot jouw telefoon contacten en foto’s</b>
        <br />
        Je kunt Vera Connect toegang geven tot jouw telefoon contacten. Dit is nodig om je inzicht
        te geven in de gegevens die jij hebt, gegevens die jij ontvangt op te slaan in je telefoon,
        te controleren of jouw contacten up-to- date zijn en die bij te werken wanneer nodig. Al
        deze acties vinden plaats op jouw telefoon. Er wordt geen informatie gedeeld en opgeslagen
        op de servers van Vera Connect. Een uitzondering hierop is het vinden van andere Vera gebruikers waarvan jij het telefoonnummer op je telefoon hebt staan. We sturen deze naar de server van Vera Connect maar slaan het niet op. Dit wordt alleen gebruikt om te filteren.
        <br className="mb-4" />
        Wanneer jij een profiel foto toevoegt in de Vera Connect app vragen wij om toegang tot jouw
        foto’s. Jij kan daarbij de gewenste foto selecteren en toevoegen aan jouw profiel. Deze foto
        wordt opgeslagen op de Vera Connect server en is zichtbaar voor jouw connecties en Vera
        Connect gebruikers die in dezelfde groepen als jij zitten.
      </p>
      <p className=" mt-3">
        <b>Je gegevens verwijderen</b>
        <br />
        Je kunt te allen tijde je profiel en je persoonsgegevens verwijderen binnen Vera Connect. Op
        het moment dat je je gegevens verwijderd in Vera Connect worden alle connecties verbroken en
        kan niemand je gegevens meer zien. Wij ook niet. Je hebt dus de volledige beheersing over je
        eigen persoonsgegevens.
      </p>
      <p className=" mt-3">
        <b>Wat mag je van ons verwachten?</b>
        <br />
        Het verbeteren van jouw privacy en bescherming van jouw gegevens gaat bij Vera Connect
        hand-in-hand met makkelijk maken om gegevens uit te wisselen en te gebruiken. Vera Connect
        is op het gebied van privacy een verbetering ten opzichte van bestaande manieren waarop je
        contactgegevens deelt omdat iedereen de baas is over eigen gegevens.
      </p>
      <p className=" mt-3">
        <b>Waar stem je meer in als je gegevens deelt met andere gebruikers via Vera Connect?</b>
        <br />
        Wanneer jij deze contactgegevens deelt met iemand stem je ermee in dat deze persoon de
        gegevens zelf kan gebruiken. Je stemt er niet mee in dat deze persoon de gegevens met derden
        mag delen. Uitzondering hierop is dat zij de gegevens delen met een partij om jou een
        kaartje of geschenk te sturen, bijvoorbeeld Greetz, Kaartje2Go en Bloomon.
      </p>
      <p className=" mt-3">
        <b>
          Wat verwachten wij van jou als je gegevens ontvangt van andere gebruikers via Vera
          Connect?
        </b>
        <br />
        De gegevens die jij ontvangt zijn niet van jou, maar van de persoon die deze met jou deelt.
        Wanneer deze persoon de toegang tot zijn/haar gegevens intrekt dien je dit te accepteren en
        eventuele externe kopieën van zijn/haar gegevens te vernietigen. Hierbij kan het
        bijvoorbeeld gaan om een geëxporteerde adressenlijst die door jou is gebruikt om kaarten te
        versturen. Andere Vera Connect gebruikers moeten erop kunnen vertrouwen dat jij bent wie je
        zegt te zijn. Gebruik daarom je eigen naam en profielfoto. Je mag je niet voordoen als een
        ander persoon. In dat geval hebben wij het recht je account te verwijderen.
      </p>
      <p className=" mt-3">
        <b>Welke persoonsgegevens worden door Vera Connect verwerkt en waarom?</b>
        <br />
        Elke Vera Connect gebruiker moet een e-mailadres en naam opgeven. Dit e-mailadres is nodig
        omdat jij hiermee kan inloggen, je persoonsgegevens kunt wijzigen en over je
        persoonsgegevens kunt beschikken. Wanneer je een actief Vera Connect account hebt, moeten
        jouw persoonsgegevens worden verwerkt om o.a.:
        <ul className="list-disc pl-6 mt-2">
          <li className="mb-1">Toegang te verlenen tot je account.</li>
          <li className="mb-1">
            Notificaties te sturen die betrekking hebben tot activiteiten van je account. Wij sturen
            o.a. notificaties wanneer iemand een uitnodiging van jouw connectie heeft geaccepteerd,
            wanneer een bestaande Vera Connectie jou een verzoek stuurt om gegevens te delen en
            wanneer een bestaande Vera Connectie jou introduceert aan iemand.
          </li>
          <li className="mb-1">
            Jouw Vera Connecties toegang te geven tot de persoonsgegevens die jij met hen deelt. Jij
            bepaalt wie welke gegevens kan zien en kan deze toegang ten alle tijden wijzigen. Lees
            meer over wat jouw Vera Connecties met jouw gegevens kunnen doen onder het kopje ‘Wat
            kunnen andere Vera Connect gebruikers met mijn persoonsgegevens doen wanneer ik deze met
            hen deel?’
          </li>
          <li className="mb-1">
            Je algemene informatie en updates te sturen Denk hierbij aan wijzingen in de
            gebruikersvoorwaarden en lancering van een nieuwe update/product.
          </li>
          <li className="mb-1">
            Te analyseren hoe onze applicatie wordt gebruikt en waar deze verbeterd kan worden.
            Hiervoor worden analyses gemaakt met alleen account id’s, dus niet met je
            persoonsgegevens zelf. Er is dus sprake van pseudonimisering. Verder monitoren we het
            gebruik zelf of te bepalen hoe de applicatie wordt gebruikt en of mensen ergens
            vastlopen. Dit wordt uitgevoerd middels Piwik PRO.
          </li>
        </ul>
        Wil je geen berichten ontvangen, schakel dit dan uit of verwijder je account.
      </p>
      <p className=" mt-3">
        <b>Wanneer zijn je persoonsgegevens zichtbaar voor Vera Connect?</b>
        <br />
        Bij Vera Connect is iedereen de baas over hun eigen gegevens. Gegevens die jij deelt via
        Vera Connect worden in jouw persoonlijke account opgeslagen en zijn niet inzichtelijk voor
        onze medewerkers.
        <br className="mb-4" />
        Bijna alle persoonsgegevens zijn volledig versleuteld en niet toegankelijk voor Vera Connect
        en haar medewerkers. Alleen jouw account e- mailadres, naam en profielfoto zijn niet
        versleuteld. Jouw e-mailadres wordt niet versleuteld om te kunnen inloggen met dit
        e-mailadres. Jouw naam en profielfoto worden getoond aan personen die jij een connectie
        verzoek stuurt en zijn om dit mogelijk te maken ook niet versleuteld. De IT-afdeling van
        Vera Connect kan deze niet versleutelde gegevens inzien indien nodig. Er is een beperking
        doorgevoerd dat alleen medewerkers die het nodig hebben om hun werk te kunnen doen voor Vera
        Connect bevoegd zijn om deze persoonsgegevens van jou te zien. Nogmaals alle overige
        persoonsgegevens die je verwerkt in Vera Connect zijn niet zichtbaar voor onze medewerkers.
        Je bent tenslotte zelf de baas over je eigen persoonsgegevens.
        <br className="mb-4" />
        Het enige moment wanneer jouw persoonsgegevens zichtbaar zijn voor onze medewerkers is bij
        de levering van stikkervellen met adreslabels of als je een kaart verstuurt naar een Vera
        Connectie. In dit geval worden de adresgegevens geëxporteerd door een Vera Connect gebruiker
        en verstuurd naar respectievelijk de afdeling de Vera Connect printshop of Print.one voor
        een kaart. Na succesvolle levering wordt alle informatie behalve de betaalde factuur
        permanent verwijderd. Deze stikkervellen/adreslabels worden nu door Vera Connect geprint en
        verstuurd. Vera Connect behoudt echter wel het recht om dit op een bepaald moment uit te
        besteden. De kaartjes worden verstuurd door Print.one. Met deze derde partij hebben wij
        afspraken gemaakt om te zorgen dat er netjes wordt omgegaan met jouw persoonlijke gegevens
        op dezelfde manier zoals wij dit doen. Wij blijven hiervoor verantwoordelijk en
        aansprakelijk.
      </p>
      <p className=" mt-3">
        <b>
          Wat kunnen andere Vera Connect gebruikers met mijn persoonsgegevens doen wanneer ik deze
          met hen deel?
        </b>
        <br />
        Vera Connect maakt het voor jou mogelijk om persoonsgegevens met andere gebruikers uit te
        wisselen. Jij kan op deze manier o.a. je voornaam, achternaam, geboortedatum,
        telefoonnummer(s), e-mailadres(sen) en adres(sen) delen. De persoon met wie jij jouw
        gegevens deelt kan deze zien en gebruiken. Het is voor jou mogelijk om te allen tijde de
        toegang tot deze gegevens terug te trekken, deze worden dan ook verwijderd uit het
        telefoonboek in de telefoon van je connecties zo gauw deze connectie maakt met Vera Connect.
        <br className="mb-4" />
        Wanneer jij je gegevens deelt met andere Vera Connect gebruikers kunnen zij o.a.:
        <ul className="list-disc pl-6 mt-2">
          <li className="mb-1">Jouw gegevens in hun telefoonboek op hun eigen telefoon zetten.</li>
          <li className="mb-1">
            Jouw gegevens gebruiken om contact op te nemen en kaarten en geschenken naar jou te
            sturen. Hierbij kunnen de gebruikte telefoonnummers, e-mailadressen en adressen extern
            worden opgeslagen door de applicaties die hiervoor worden gebruikt. Bijvoorbeeld:
            wanneer iemand via Vera Connect een telefoonnummer gebruikt om te bellen wordt dit
            nummer opgeslagen in de historie van de gebruikte belapplicatie. De gebruiker is
            hiervoor zelf verantwoordelijk.
          </li>
          <li className="mb-1">
            Een adressenbestand exporteren, bijvoorbeeld om kaarten en geschenken te versturen via
            externe partijen waaronder Hallmark en Kaartje2Go. Dit adressenbestand kan met deze of
            andere webshops worden gedeeld door de persoon met wie jij jouw persoonsgegevens hebt
            gedeeld wanneer zij daar een bestelling plaatsen. De gebruiker is hiervoor zelf
            verantwoordelijk.
          </li>
          <li className="mb-1">
            Een kaart versturen via Print.one. De Vera Connect gebruiker stuurt dan de
            persoonsgegevens naar Print.one. Met Print.one hebben wij afspraken gemaakt dat zij
            zorgvuldig met je persoonsgegevens moeten omgaan en deze na het versturen van de kaart
            moeten verwijderen.
          </li>
          <li className="mb-1">
            Stickervellen met adreslabel bij Vera Connect bestellen. In dit geval worden de
            adresgegevens geëxporteerd door een Vera Connect gebruiker en verstuurd naar de afdeling
            de Vera Connect printshop. Deze vellen worden geprint en verstuurd naar de persoon die
            ze besteld hebben. Na succesvolle levering wordt alle informatie behalve de betaalde
            factuur permanent verwijderd.
          </li>
        </ul>
      </p>
      <p className=" mt-3">
        <b>Hoe lang bewaren we jouw persoonsgegevens?</b>
        <br />
        De persoonlijke gegevens die gekoppeld zijn aan jouw account worden bewaard zolang dit
        account actief is. Het opheffen van je account is te allen tijde mogelijk en kan je zelf
        doen op www.veraconnect.app. Door je account op te heffen worden alle gegevens direct uit
        alle Vera Connect databases verwijderd. Ook de andere Vera Connect gebruikers waarmee je je
        persoonsgegevens hebt gedeeld kunnen deze niet meer inzien.
      </p>
      <p className=" mt-3">
        <b>Hoe beveiligen we jouw persoonsgegevens?</b>
        <br />
        De beveiliging van jouw persoonsgegevens is voor ons belangrijk. De contactgegevens die jij
        via Vera Connect deelt met andere gebruikers zijn veilig en versleuteld opgeslagen. Wij
        gebruiken verschillende technieken om te voorkomen dat communicatie tussen server en browser
        onderschept kunnen worden en ongewenste aanvallen (bijv. Cross Site Scripting) worden
        verhinderd.
      </p>
      <p className=" mt-3">
        <b>Klachten</b>
        <br />
        Heb je klachten ten aanzien van de verwerking van jouw persoonlijke gegevens? Dan gaan we
        uiteraard graag met je in gesprek om samen tot een oplossing te komen. Bel of mail ons dus
        zeker. Onze contactgegevens vind je hieronder bij ‘Wie is Vera Connect’. Mocht je hierna
        alsnog niet tevreden zijn, dan heb je altijd het recht om een klacht in te dienen bij de
        Autoriteit Persoonsgegevens (AP) (autoriteitpersoonsgegevens.nl).
      </p>
      <p className=" mt-3">
        <b>Wie is Vera Connect?</b>
        <br />
        ‘Vera Connect B.V.’ is een dochterbedrijf van ‘PBCVE Holding B.V.’. Ons adres is
        Macronistraat 16 (11de verdieping), 3029 AK, Rotterdam. Je kun ons per mail bereiken op
        privacy@veraconnect.nl.
      </p>
      <p className=" mt-3">
        <b>Help jij ons Vera Connect te verbeteren?</b>
        <br />
        Vera Connect is gecommitteerd om stap-voor-stap jouw privacy en het gebruik van Vera Connect
        te verbeteren. Hiervoor worden verbeteringen doorgevoerd aan de huidige applicatie en nieuwe
        functionaliteiten uitgerold. Als gebruiker van Vera Connect kan je ons erg helpen door
        eventuele vragen en opmerkingen te sturen naar info@veraconnect.nl. Zo maken we samen een
        applicatie die jouw privacy verbetert en jouw leven leuker maakt.
      </p>
    </>
  );
}
