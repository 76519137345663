import React, { useEffect } from 'react';
import { HiUserGroup } from 'react-icons/hi2';
import { FaSearch } from 'react-icons/fa';
import classNames from 'classnames';
import { CommunityInvite } from '../../types/invites';
import { CommunityMember, CommunityRole } from '../../types/misc';
import { communityService } from '../../services';
import { getInitials } from '../../utils/stringUtils';
import PhoneLayout from '../layouts/PhoneLayout';
import ApiImage from '../misc/ApiImage';

interface CommunityOverviewScreenProps {
  invite?: CommunityInvite;
}

export default function CommunityOverviewScreen({
  invite,
}: CommunityOverviewScreenProps): JSX.Element {
  if (!invite) return <></>;
  const [members, setMembers] = React.useState<CommunityMember[]>([]);
  const [search, setSearch] = React.useState<string>('');

  useEffect((): void => {
    communityService.getCommunityMembers(invite.communityId).then((res): void => setMembers(res));
  }, []);

  const memberList: Record<string, CommunityMember[]> = Array.from({ length: 26 }, (_, i) => {
    const letter = String.fromCharCode('A'.charCodeAt(0) + i);
    return {
      [letter]: members.filter(
        (m) =>
          m.firstName.toUpperCase().startsWith(letter) &&
          `${m.firstName}${m.lastName}`
            .toLowerCase()
            .replace(' ', '')
            .includes(search.toLowerCase().replace(' ', '')),
      ),
    };
  }).reduce(
    (acc, cur): Record<string, CommunityMember[]> => ({
      ...acc,
      ...(Object.values(cur)[0].length ? cur : {}),
    }),
    {},
  );

  return (
    <PhoneLayout className=" bg-secondary-50">
      <div className="rounded-b-[40px] pt-10 px-5 py-5 gap-5 bg-secondary-200 w-full flex flex-col">
        <div className="flex gap-4 items-end">
          {invite.communityPicture && (
            <ApiImage
              src={invite.communityPicture}
              alt={invite.communityName}
              className="rounded-[8px] object-cover w-[80px] h-[80px]"
            />
          )}
          <div className="flex flex-col justify-center w-fit h-fit ">
            <div className="flex gap-1 w-fit h-fit">
              <HiUserGroup className="w-4 h-4" />
              <p className="text-primary-900 text-xs">{invite.communitySize}</p>
            </div>
            <p className="text-primary-900 font-serif text-lg">{invite.communityName}</p>
          </div>
        </div>
        <p className="text-primary-900 text-sm">{invite.communityDescription}</p>
      </div>

      <div className="flex flex-col gap-2 px-5 py-5">
        <div className="h-fit w-fit relative mb-2">
          <input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e): void => setSearch(e.target.value)}
            className="rounded-full border border-primary bg-secondary-50 text-primary focus:border-primary pl-7 focus:ring-0 h-8 w-full px-5 py-2 text-sm"
          />
          <FaSearch className="absolute top-2 left-2 text-primary" />
        </div>
        {Object.keys(memberList).map(
          (letter): JSX.Element => (
            <div key={letter} className="flex flex-col gap-1">
              <p className="text-primary font-medium">{letter}</p>
              {memberList[letter].map(
                (member, index): JSX.Element => (
                  <div className="w-full flex gap-2">
                    {member.picture ? (
                      <ApiImage
                        src={member.picture}
                        className="w-9 h-9 rounded object-cover"
                        alt={member.firstName}
                      />
                    ) : (
                      <div className="rounded flex-shrink-0 w-9 h-9 bg-secondary flex items-center justify-center p-1 text-secondary-50">
                        {getInitials(`${member.firstName}+${member.lastName}`)}
                      </div>
                    )}
                    <div
                      className={classNames(
                        'items-center text-sm w-full border-secondary-200 text-primary-900 font-medium ',
                        {
                          'border-b': index !== memberList[letter].length - 1,
                          'pt-1.5': member.role !== CommunityRole.ADMIN,
                          'pb-4': memberList[letter].length !== 1,
                        },
                      )}>
                      {member.role === CommunityRole.ADMIN && (
                        <p className="text-[10px] text-primary -mb-[5px]">Admin</p>
                      )}
                      {member.firstName} {member.lastName}
                    </div>
                  </div>
                ),
              )}
            </div>
          ),
        )}
      </div>
    </PhoneLayout>
  );
}
