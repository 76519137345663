import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ProfileField } from '../../../types/profile';
import useDelayedEffect from '../../../hooks/useDelayedEffect';
import { IsValidField } from '../../../utils/validationUtils';

interface BirthDateInputProps {
  field: ProfileField;
  setField: (field: ProfileField) => void;
  canBeEmpty?: boolean;
  mandatory?: boolean;
  label?: boolean;
  validate?: boolean;
}

export default function BirthDateInput({
  field,
  setField,
  canBeEmpty = false,
  mandatory = false,
  label = false,
  validate = false,
}: BirthDateInputProps): JSX.Element {
  const [year, month, day] = field?.birthDate?.split('-') || ['', '', ''];
  const [error, setError] = useState<string>('');
  const { t } = useTranslation();

  useDelayedEffect(() => {
    if (!validate) return;
    if (!year && !month && !day) {
      setError(canBeEmpty ? '' : t('invalid.empty'));
      return;
    }
    if (!IsValidField(field, canBeEmpty)) {
      setError(t('invalid.invalid'));
      return;
    }
    setError('');
  }, [field.birthDate, validate]);

  useEffect(() => {
    if (!year && !month && !day && field.birthDate) {
      setField({ ...field, birthDate: '' });
    }
  }, [field]);

  return (
    <div>
      {label && (
        <label className=" pr-2 font-medium text-base mb-1">
          {t('form.birthDate.label')} {mandatory ? '*' : ''}
        </label>
      )}
      <div className="flex flex-col w-fit relative">
        <label htmlFor={field.id} className="pr-2 mb-1 text-sm">
          {t('form.birthDate.date')}
        </label>
        <div className="flex flex-row gap-2 w-full items-center">
          <input
            name="bday-day"
            id={field.id}
            type="text"
            className={classNames(
              'px-2 border border-gray-400 w-[45px] flex items-center rounded-lg mr-1 h-10 text-gray-600 focus:ring-0 focus:ring-transparent',
              {
                '!border-red-500': error,
              },
            )}
            placeholder="DD"
            data-testid={`suffix-input-${field.id}`}
            value={day || ''}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9]/g, '');
              setField({ ...field, birthDate: `${year || ''}-${month || ''}-${value}` });
            }}
            onBlur={(e) => {
              if (e.target.value.length === 1) {
                const value = `0${e.target.value}`;
                setField({ ...field, birthDate: `${year || ''}-${month || ''}-${value}` });
              }
            }}
          />
          -
          <input
            name="bday-month"
            id={field.id}
            type="text"
            className={classNames(
              'px-2 border border-gray-400 w-[45px] flex items-center rounded-lg mr-1 h-10 text-gray-600 focus:ring-0 focus:ring-transparent',
              {
                '!border-red-500': error,
              },
            )}
            placeholder="MM"
            data-testid={`suffix-input-${field.id}`}
            value={month || ''}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9]/g, '');
              setField({ ...field, birthDate: `${year || ''}-${value}-${day || ''}` });
            }}
            onBlur={(e) => {
              if (e.target.value.length === 1) {
                const value = `0${e.target.value}`;
                setField({ ...field, birthDate: `${year || ''}-${value}-${day || ''}` });
              }
            }}
          />
          -
          <input
            name="bday-year"
            id={field.id}
            type="text"
            className={classNames(
              'px-2 border border-gray-400 w-[65px] flex items-center rounded-lg mr-1 h-10 text-gray-600 focus:ring-0 focus:ring-transparent',
              {
                '!border-red-500': error,
              },
            )}
            placeholder="YYYY"
            data-testid={`suffix-input-${field.id}`}
            value={year || ''}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9]/g, '');
              setField({ ...field, birthDate: `${value}-${month || ''}-${day || ''}` });
            }}
            onBlur={(e) => {
              if (e.target.value.length === 1) {
                const value = `0${e.target.value}`;
                setField({ ...field, birthDate: `${value}-${month || ''}-${day || ''}` });
              }
            }}
          />
        </div>
        <p className="text-red-500 absolute top-0 right-1 text-sm transition-all">{error}</p>
      </div>
    </div>
  );
}
