import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import NavBarLogo from '../misc/NavBarLogo';
import useAuth from '../../hooks/useAuth';
import { authenticationService } from '../../services';
import { detectMobileOS } from '../../utils/userAgentUtils';
import { MobileOS } from '../../types/misc';
import Footer from '../misc/Footer';
import useLanguage from '../../hooks/useLanguage';
import { setLanguage } from '../../redux/slices/applicationSlice';
import { Language } from '../../types/requests';
import DownloadButton from '../misc/DownloadButton';

interface CommonPageWrapperProps {
  children: React.ReactNode;
  includeCommon?: boolean;
}
function CommonPageWrapper({
  children,
  includeCommon = true,
}: CommonPageWrapperProps): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useAuth();
  const mobileOS = detectMobileOS();
  const lang = useLanguage();
  const dispatch = useDispatch();

  const title = React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.type === Title,
  );
  const message = React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.type === Message,
  );
  const body = React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.type === Body,
  );

  const other = React.Children.toArray(children).filter(
    (child) =>
      React.isValidElement(child) &&
      child.type !== Title &&
      child.type !== Message &&
      child.type !== Header &&
      child.type !== Body,
  );

  const header = React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.type === Header,
  );
  return (
    <>
      {' '}
      <div className="w-full h-16 sticky top-0 bg-secondary-200 rounded-b-[20px] flex z-50 items-center">
        <div className="flex w-full max-w-7xl justify-between items-center mx-auto desktop:px-0 px-4">
          <NavBarLogo />
          <div className="flex gap-4 items-center">
            {auth && (
              <p
                onClick={() => {
                  authenticationService.logOut();
                  navigate('/');
                }}
                className="font-medium cursor-pointer">
                {t('general.logOut')}
              </p>
            )}
            <img
              src={`${process.env.PUBLIC_URL}/images/flags/${
                lang === Language.NL ? Language.EN : Language.NL
              }.svg`}
              alt="Nederlands"
              onClick={() =>
                dispatch(setLanguage(lang === Language.NL ? Language.EN : Language.NL))
              }
              className="w-6 h-6 cursor-pointer"
            />
          </div>
        </div>
      </div>
      <div className="min-h-[calc(100vh-128px)] bg-secondary-50 ">
        <div className="w-full bg-secondary-50 display-stretch rounded-2xl px-4 my-8 max-w-7xl mx-auto gap-8 flex md:flex-row flex-col-reverse">
          {/* Success image */}
          {header}

          {/* Message and download banners */}
          <div className="flex flex-col md:w-[70%] h-fit pt-4 ">
            <div
              className={classNames(' border-secondary-200 ', {
                'border-b mb-10 pb-10': other.length > 0 && includeCommon,
              })}>
              {title}
              {message}
              {body}
              {includeCommon && (
                <>
                  <p className="text-base font-normal my-4">
                    {t('page.common.message')}
                  </p>
                  <DownloadButton/>
                </>
              )}
            </div>
            {other}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

function Title({ children }: { children: React.ReactNode }): JSX.Element {
  return <h1 className="text-[28px]  font-semibold font-serif ">{children}</h1>;
}

function Message({ children }: { children: React.ReactNode }): JSX.Element {
  return <h2 className="text-2xl font-[600px] mt-2 font-serif">{children}</h2>;
}

function Body({ children }: { children: React.ReactNode }): JSX.Element {
  return <>{children}</>;
}

interface HeaderProps {
  children: React.ReactNode;
}

function Header({ children }: HeaderProps): JSX.Element {
  return <>{children}</>;
}

CommonPageWrapper.Title = Title;
CommonPageWrapper.Message = Message;
CommonPageWrapper.Header = Header;
CommonPageWrapper.Body = Body;

export default CommonPageWrapper;
