import React, { useEffect, useLayoutEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useDeleteParams from '../../../hooks/useDeleteParams';
import { authenticationService, profileService } from '../../../services';
import { EmailPreferenceType } from '../../../types/misc';
import useAuth from '../../../hooks/useAuth';
import CommonPageWrapper from '../../../components/layouts/CommonPageWrapper';
import { redirectToApp } from '../../../utils/appUtils';
import ResponsePageWrapper from '../../../components/layouts/ResponsePageWrapper';
import InvalidAuthHeader from '../../../components/headers/InvalidAuthHeader';
import { InviteType } from '../../../types/invites';
import InvalidAuthBody from '../../../components/misc/InvalidAuthBody';
import Button from '../../../components/misc/Button';

export default function EmailPreferencesPage(): JSX.Element {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const auth = useAuth();

  const link = params.get('link');
  useDeleteParams('link');

  if (link) authenticationService.loginMagic(link).catch(() => {});

  const type = params.get('type') as EmailPreferenceType | undefined;

  useEffect(() => {
    if (!type || !Object.values(EmailPreferenceType).includes(type)) {
      navigate('/');
      toast.error(t('toast.error.general.invalidInvite'));
    }
  }, [JSON.stringify(auth)]);

  useLayoutEffect((): void => {
    redirectToApp(link, null, 'preferences', null);
  }, []);

  const handleSubmit = async () => {
    await profileService.updateUserPreferences([type!]);
    navigate('/');
    toast.success('succes');
  };

  const typeToString = (): string => {
    switch (type) {
      case EmailPreferenceType.COMMUNITYEMAIL:
        return t('page.emailPreferences.group');
      case EmailPreferenceType.DATAREQUESTEMAIL:
        return t('page.emailPreferences.dataRequest');
      case EmailPreferenceType.INTRODUCTIONEMAIL:
        return t('page.emailPreferences.introduction');
      case EmailPreferenceType.NEWCONNECTIONEMAIL:
        return t('page.emailPreferences.connection');
      default:
        return '';
    }
  };

  if (!auth)
    return (
      <ResponsePageWrapper>
        <ResponsePageWrapper.Header>
          <InvalidAuthHeader type={InviteType.EMAIL_PREFERENCE} />
        </ResponsePageWrapper.Header>
        <InvalidAuthBody invitationId={type as string} inviteType={InviteType.EMAIL_PREFERENCE} />
      </ResponsePageWrapper>
    );

  return (
    <CommonPageWrapper>

      <CommonPageWrapper.Title>{t('page.emailPreferences.title')}</CommonPageWrapper.Title>

      <CommonPageWrapper.Body>
        <div className="flex flex-col pb-8 mb-8 border-b border-secondary-200">
          <p className="font-[600px] mt-2 ">
            {t('page.emailPreferences.description')}
          </p>
          <Button className="mt-4 w-fit px-[32px]" variant="primary" onClick={handleSubmit}>
            {t('page.emailPreferences.button', { type: typeToString() })}
          </Button>
        </div>
      </CommonPageWrapper.Body>
    </CommonPageWrapper>
  );
}
