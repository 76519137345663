import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomField, CustomFieldResponses, CustomFieldType } from '../../types/invites';
import { filterCustomLabelFields } from '../../utils/filterUtils';

interface CustomFieldsFormProps {
  fields: CustomField[];
  response: CustomFieldResponses;
  setResponse: (response: CustomFieldResponses) => void;
  label?: boolean;
}

export default function CustomFieldsForm({
  fields,
  response,
  setResponse,
  label = false,
}: CustomFieldsFormProps): JSX.Element {
  const { t } = useTranslation();

  const renderField = (field: CustomField, index: number): JSX.Element => {
    switch (field.type) {
      case CustomFieldType.TEXT:
        return (
          <div key={index} className=" overflow-visible w-full">
            <p className=" pb-1 pr-2 text-sm">{`${field.label} ${field.mandatory ? '*' : ''}`}</p>
            <input
              type="text"
              placeholder={field.label}
              className="px-2 w-full focus:ring-0 h-[40px]"
              value={response[field.customFieldId]}
              onChange={(e): void => {
                const newResponse = { ...response };
                newResponse[field.customFieldId] = e.target.value;
                setResponse(newResponse);
              }}
            />
          </div>
        );
      case CustomFieldType.MULTIPLE_CHOICE:
        return (
          <div key={index} className=" overflow-visible w-full">
            <p className="pr-2 text-sm">{`${field.label} ${field.mandatory ? '*' : ''}`}</p>
            {field.options.map((option, j): JSX.Element => {
              return (
                <div key={j} className="flex items-center gap-3">
                  <input
                    className="form-checkbox border-primary cursor-pointer bg-secondary-50 h-5 w-5 text-primary-300 accent-primary-900 rounded-full focus:ring-0 focus:ring-transparent"
                    type="checkbox"
                    checked={response[field.customFieldId] === option}
                    onChange={(): void => {
                      const newResponse = { ...response };
                      newResponse[field.customFieldId] =
                        response[field.customFieldId] !== option ? option : '';
                      setResponse(newResponse);
                    }}
                  />
                  <p className="text-sm py-2">{option}</p>
                </div>
              );
            })}
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div>
      {filterCustomLabelFields(fields).length > 0 && label && (
        <label className=" pr-2 font-medium text-base mb-1">{t('form.customFields.label')}</label>
      )}
      <div className="flex gap-3 flex-col">{fields.map(renderField)}</div>
    </div>
  );
}
