import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import NavBarLogo from '../misc/NavBarLogo';
import { authenticationService } from '../../services';
import useAuth from '../../hooks/useAuth';
import Footer from '../misc/Footer';
import { setLanguage } from '../../redux/slices/applicationSlice';
import { Language } from '../../types/requests';
import useLanguage from '../../hooks/useLanguage';

interface PageWrapperProps {
  children: React.ReactNode;
  handleLogin?: () => void;
}

function ResponsePageWrapper({ children, handleLogin }: PageWrapperProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang = useLanguage();

  const header = React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.type === Header,
  );

  const sidePanel = React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.type === SidePanel,
  );

  const auth = useAuth();

  return (
    <>
      <div className="w-full h-16 sticky top-0 bg-secondary-200 rounded-b-[20px] flex z-50 items-center">
        <div className="flex w-full max-w-7xl justify-between items-center mx-auto desktop:px-0 px-4">
          <NavBarLogo />
          <div className="flex items-center gap-4">
            {handleLogin && !auth && (
              <p onClick={() => handleLogin?.()} className="font-medium cursor-pointer">
                {t('general.logIn')}
              </p>
            )}
            {auth && (
              <p onClick={authenticationService.logOut} className="font-medium cursor-pointer">
                {t('general.logOut')}
              </p>
            )}
            <img
              src={`${process.env.PUBLIC_URL}/images/flags/${
                lang === Language.NL ? Language.EN : Language.NL
              }.svg`}
              alt="Nederlands"
              onClick={() =>
                dispatch(setLanguage(lang === Language.NL ? Language.EN : Language.NL))
              }
              className="w-6 h-6 cursor-pointer"
            />
          </div>
        </div>
      </div>
      <div className="min-h-[calc(100vh-192px)] bg-secondary-50 my-8">
        <div className="flex-col md:flex-row w-full flex justify-between max-w-7xl mx-auto flex-1 gap-6">
          {sidePanel ?? (
            <div className="md:flex hidden bg-primary-300 min-h-[400px] md:min-h-[600px] place-items-center justify-center items-center h-full py-24 sticky top-[96px] z-10 overflow-hidden rounded-3xl w-[65%]">
              <img
                src={`${process.env.PUBLIC_URL}/images/figures/phone.svg`}
                alt="Vera Phone"
                className="w-[40%]"
              />
            </div>
          )}

          <div className="w-full md:w-[43%] md:min-w-[420px] min-h-[400px] md:min-h-[600px] bg-secondary-50 h-fit rounded-3x flex flex-col gap-4 pt-4 ">
            <div className="w-full ">{header}</div>
            <div className="h-full flex flex-col w-full px-4 md:px-0 rounded-b-3xl">
              {React.Children.toArray(children).filter(
                (child) =>
                  React.isValidElement(child) && child.type !== Header && child.type !== SidePanel,
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

interface HeaderProps {
  children: React.ReactNode;
}

function Header({ children }: HeaderProps): JSX.Element {
  return (
    <div className="bg-secondary-50 rounded-3xl md:rounded-b-none w-full h-fit gap-2.5 flex flex-col px-4 md:p-0">
      {children}
    </div>
  );
}

interface SidePanelProps {
  children: React.ReactNode;
  className?: string;
}

function SidePanel({ children, className }: SidePanelProps): JSX.Element {
  return (
    <div
      className={twMerge(
        'md:flex hidden bg-primary-300 min-h-[400px] md:min-h-[600px] place-items-center justify-center items-center h-full py-24 sticky top-[96px] z-10 overflow-hidden rounded-3xl w-[65%]',
        className,
      )}>
      {children}
    </div>
  );
}
ResponsePageWrapper.SidePanel = SidePanel;

ResponsePageWrapper.Header = Header;

export default ResponsePageWrapper;
