import { setProfileFields, updateProfileField } from '../redux/slices/applicationSlice';
import { store } from '../redux/store';
import { EmailPreferenceType } from '../types/misc';
import { ProfileField, UserStats } from '../types/profile';
import { RootState } from '../types/redux';
import { CheckEmailRequest, UpdatePreferencesBody } from '../types/requests';
import { CheckEmailResponse, GetPreferencesResponse } from '../types/responses';
import httpService from './httpService';

export function getUserData(): Promise<ProfileField[]> {
  return httpService.get<ProfileField[]>('/getData').then(({ data }): ProfileField[] => {
    store.dispatch(setProfileFields(data));
    return data;
  });
}

export function addFields(field: ProfileField[], includeAll = false): Promise<ProfileField[]> {
  return httpService.post<ProfileField[]>('/setData', field).then(({ data }): ProfileField[] => {
    const difference = data.filter(
      (nf) =>
        !(store.getState() as RootState).application.profileFields
          ?.map((f) => f.id)
          .includes(nf.id),
    );
    store.dispatch(setProfileFields(data));
    return includeAll ? data : difference;
  });
}

export async function updateField(field: ProfileField): Promise<ProfileField> {
  return httpService.put<ProfileField[]>('/business/profile', [field]).then((): ProfileField => {
    store.dispatch(updateProfileField(field));
    return field;
  });
}

export async function checkEmailExists(email: string): Promise<boolean> {
  const body: CheckEmailRequest = { email };

  return httpService
    .post<CheckEmailResponse>('/auth/checkEmailStatus', body)
    .then(({ data }): boolean => data.hasAccount);
}

export async function getUserStatistics(): Promise<UserStats> {
  return httpService.get<UserStats>('/user/statistics').then(({ data }): UserStats => data);
}

export async function getUserPreferences(): Promise<EmailPreferenceType[]> {
  return httpService
    .get<GetPreferencesResponse>('/updatePreferences')
    .then(({ data }): EmailPreferenceType[] => data.userPreferences);
}

export async function updateUserPreferences(preferences: EmailPreferenceType[]): Promise<void> {
  const body: UpdatePreferencesBody = {
    userPreferences: preferences,
  };
  return httpService.put('/updatePreferences', body);
}
