import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useEffectAfterFirstRender from '../../../hooks/useEffectAfterFirstRender';
import useDelayedEffect from '../../../hooks/useDelayedEffect';

interface NameInputProps {
  alias: string;
  setAlias: (alias: string) => void;
  mandatory?: boolean;
  label?: boolean;
  validate?: boolean;
}
export default function NameInput({
  alias,
  setAlias,
  mandatory = false,
  label = false,
  validate = false,
}: NameInputProps): JSX.Element {
  const { t } = useTranslation();
  const [error, setError] = useState('');

  let firstName = '';
  let lastName = '';
  if (alias.includes('+')) [firstName, lastName] = alias.split('+');

  useDelayedEffect(() => {
    if (!validate) return;
    if (!firstName && !lastName) {
      setError(t('invalid.empty'));
      return;
    }
    if (!firstName) {
      setError(t('invalid.firstName'));
      return;
    }
    if (!lastName) {
      setError(t('invalid.lastName'));
      return;
    }
    setError('');
  }, [alias, validate]);

  useEffectAfterFirstRender(() => {
    if (!firstName && !lastName) setAlias('');
  }, [firstName, lastName]);

  return (
    <div>
      {label && (
        <label className=" pr-2 font-medium text-base mb-1">
          {t('form.name.label')} {mandatory ? '*' : ''}
        </label>
      )}
      <div className="w-full flex flex-row gap-2 relative">
        <div className="flex flex-col w-full">
          <label className=" pr-2  text-sm mb-1">{t('form.name.firstName')}</label>
          <input
            type="text"
            name="firstName"
            placeholder={t('form.name.firstName')}
            className={classNames(
              'px-2 border rounded-full h-10 text-gray-600 w-full focus:ring-0 focus:ring-transparent ',
              {
                '!border-red-500': error,
              },
            )}
            value={firstName}
            onChange={(e) => setAlias(`${e.target.value}+${lastName}`)}
          />
        </div>
        <div className="flex flex-col w-full">
          <label className=" pr-2  text-sm mb-1">{t('form.name.lastName')}</label>
          <input
            type="text"
            name="lastName"
            placeholder={t('form.name.lastName')}
            value={lastName}
            className={classNames(
              'px-2 border rounded-full h-10 text-gray-600 w-full focus:ring-0 focus:ring-transparent ',
              {
                '!border-red-500': error,
              },
            )}
            onChange={(e) => setAlias(`${firstName}+${e.target.value}`)}
          />
        </div>
        <p className="text-red-500 absolute top-0 right-1 text-sm transition-all">{error}</p>
      </div>
    </div>
  );
}
