import React, { useEffect, useLayoutEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { HiTrash } from 'react-icons/hi';
import ResponsePageWrapper from '../../components/layouts/ResponsePageWrapper';
import {
  authenticationService,
  connectionService,
  invitationService,
  profileService,
} from '../../services';
import { InviteType, PersonalInvite } from '../../types/invites';
import { ProfileDataType, ProfileField } from '../../types/profile';
import {
  addPhoneNumberCountryCode,
  createEmptyField,
  convertPersonalInviteToProfileFields,
} from '../../utils/formatUtils';
import TermsAndConditions from '../../components/misc/TermsAndConditions';
import ProfileFieldForm from '../../components/forms/profile/ProfileFieldForm';
import EmailInput from '../../components/forms/profile/EmailInput';
import InviteHeader from '../../components/headers/InviteHeader';
import { sortProfileFields } from '../../utils/sortUtils';
import { isEmptyField, isValidProfileFields } from '../../utils/validationUtils';
import { LoginPageState, SuccessPageState } from '../../types/navigation';
import NameInput from '../../components/forms/profile/NameInput';
import BusinessNameInput from '../../components/forms/profile/BusinessNameInput';
import PrimaryEmailModal from '../../components/modals/PrimaryEmailModal';
import Button from '../../components/misc/Button';
import { InvitationDetails, UserAccountCreationChannel, UserAccountCreationEvent } from '../../analytics/models';

export default function UnauthenticatedPersonalInvitePage(): JSX.Element {
  const { t } = useTranslation();
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const invitationId = params.get('invitationId');
  const link = params.get('link');

  if (link) authenticationService.loginMagic(link);

  const [invite, setInvite] = useState<PersonalInvite>();
  const [alias, setAlias] = useState<string>('');
  const [profileFields, setProfileFields] = useState<ProfileField[]>([]);
  const [accountExists, setAccountExists] = useState<boolean>(false);
  const [terms, setTerms] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [validate, setValidate] = useState<boolean>(false);

  const emailField = profileFields.find((x) => x.dataType === ProfileDataType.EMAIL);
  const businessName = profileFields.find((x) => x.dataType === ProfileDataType.BUSINESSNAME);

  useEffect(() => {
    if (!invitationId) {
      toast.error(t('toast.error.general.invalidInvite'));
      navigate('/');
    }
  }, []);

  useLayoutEffect(() => {
    if (!invitationId || link) return;

    const setFields = async () => {
      const inv =
        (location.state?.personalInvite as PersonalInvite) ||
        (await invitationService.getPersonalInvite(invitationId).catch(() => {
          toast.error(t('toast.error.general.inviteNotFound'));
          navigate('/');
        }));

      if (!inv) return;
      setInvite(inv);

      const dataRequest =
        inv.data.requestedDataTypes.length > 0
          ? inv.data.requestedDataTypes
          : Array.from(
              new Set([
                ProfileDataType.BUSINESSNAME,
                ProfileDataType.EMAIL,
                ProfileDataType.ADDRESS,
                ProfileDataType.BIRTHDATE,
                ProfileDataType.PHONENUMBER,
              ]),
            );

      const knownInformation = convertPersonalInviteToProfileFields(inv);

      const fields = sortProfileFields(
        dataRequest
          .map((f) => {
            const info =
              knownInformation.filter((x) => x.dataType === f && !isEmptyField(x)) ||
              createEmptyField(f);
            return info.length || f === ProfileDataType.BUSINESSNAME ? info : [createEmptyField(f)];
          })
          .flat()
          .filter((x) => !!x),
      );
      setProfileFields(fields);

      setAlias(`${inv.data.firstName || ''}+${inv.data.lastName || ''}`);
    };

    setFields();
  }, []);

  const validateFields = (): boolean => {
    if (!alias || !alias.split('+')[0] || !alias.split('+')[1]) {
      toast.error(t('toast.error.field.name'));
      return false;
    }
    if (!terms) {
      toast.error(t('toast.error.general.terms'));
      return false;
    }
    if (isEmptyField(profileFields.find((x) => x.dataType === ProfileDataType.EMAIL)!)) {
      toast.error(t('toast.error.field.email'));
      return false;
    }
    return isValidProfileFields(profileFields);
  };

  const handleSave = async () => {
    setValidate(true);
    if (!validateFields()) return;
    if (profileFields.filter((x) => x.dataType === ProfileDataType.EMAIL).length > 1) {
      setModalOpen(true);
      return;
    }
    if (!emailField?.email) {
      toast.error(t('toast.error.field.email'));
      return;
    }
    await handleSubmit(emailField?.email!);
  };

  const handleSubmit = async (email: string) => {
    const event: UserAccountCreationEvent<InvitationDetails> = {
      channel: UserAccountCreationChannel[UserAccountCreationChannel.PERSONAL_INVITE],
      details: { inviteLink: invitationId || '' },
    };
    const login = await authenticationService.register(alias, email!, event);

    const propertyIds = await profileService
      .addFields(
        profileFields
          .filter((x) => !isEmptyField(x) && x.email !== email)
          .map(addPhoneNumberCountryCode),
      )
      .then((f) => f.map((x) => x.id!));
    await connectionService.createPersonalConnection(
      invitationId!,
      propertyIds.map((x): number => +x),
    );
    const state: SuccessPageState = {
      translationKey: 'page.common.success.message.invite',
      translationOptions: {
        requesterName: requesterName || '',
      },
      requesterId: `${invite?.inviter.userId!}`,
      requesterAlias: `${invite?.inviter.firstName}+${invite?.inviter.lastName}`,
    };
    navigate('/success', { state });
    login();
  };

  const handleLogin = async () => {
    const state: LoginPageState = {
      email: emailField?.email! || '',
      invitationId: invitationId!,
      inviteType: InviteType.PERSONAL,
      personalInvite: invite!,
    };
    navigate('/login', { state });
  };

  const setProfileFieldByIndex = (field: ProfileField, index: number): void => {
    setProfileFields(
      profileFields.map((f: ProfileField, i: number): ProfileField => (i === index ? field : f)),
    );
  };

  const indexByType = (field: ProfileField): number => {
    return profileFields.filter((x) => x.dataType === field.dataType).indexOf(field);
  };

  const requesterName = invite?.inviter.firstName || '';

  return (
    <>
      <ResponsePageWrapper handleLogin={handleLogin}>
        <ResponsePageWrapper.Header>
          <InviteHeader
            requesterName={requesterName || ''}
            message={t('page.personalInvite.headerMessage', { requesterName })}
          />
        </ResponsePageWrapper.Header>

        <div className="flex flex-col">
          <h2 className="font-medium text-lg">{t('page.shared.shareData')}</h2>
          <p className="text-sm">{t('page.personalInvite.message', { requesterName })}</p>
        </div>
        <div className="gap-4 flex flex-col mt-4">
          <div className="w-[92%] gap-4 flex flex-col">
            <NameInput alias={alias} setAlias={setAlias} mandatory label validate={validate} />

            {businessName && (
              <BusinessNameInput
                field={businessName}
                setField={(field) =>
                  setProfileFieldByIndex(field, profileFields.indexOf(businessName))
                }
                label
              />
            )}

            {emailField && (
              <EmailInput
                field={emailField!}
                emailCheck
                label
                validate={validate}
                mandatory
                setAccountExists={setAccountExists}
                setField={(field) =>
                  setProfileFieldByIndex(field, profileFields.indexOf(emailField))
                }
              />
            )}
            {accountExists ? (
              <span className="text-sm text-red-500">
                {t('page.shared.emailInUse')}{' '}
                <strong className="cursor-pointer underline" onClick={handleLogin}>
                  {t('page.shared.logInHere')}
                </strong>
                .
              </span>
            ) : (
              ''
            )}
          </div>
          {profileFields.map((field, index) => {
            return (field.dataType === ProfileDataType.EMAIL && indexByType(field) === 0) ||
              field.dataType === ProfileDataType.BUSINESSNAME ? (
              <></>
            ) : (
              <div className={`flex gap-2 ${indexByType(field) > 0 ? '-mt-1' : ''}`}>
                <div className="w-[92%]">
                  <ProfileFieldForm
                    field={field}
                    label={indexByType(field) === 0}
                    canBeEmpty
                    validate={validate}
                    setField={(f) => setProfileFieldByIndex(f, index)}
                  />
                </div>

                {indexByType(field) !== 0 && (
                  <button
                    type="button"
                    className="w-fit h-fit mt-9"
                    onClick={() => setProfileFields(profileFields.filter((_, i) => i !== index))}>
                    <HiTrash className="h-5 w-5 text-red-500" />
                  </button>
                )}
              </div>
            );
          })}
        </div>
        <TermsAndConditions
          className="mt-4"
          checked={terms}
          setChecked={setTerms}
          name={requesterName}
        />
        <div className="flex flex-1 h-full justify-end items-end align-bottom min-h-10 mt-4">
          <Button variant="primary" className="px-[32px]" onClick={handleSave}>
            {t('page.shared.acceptInvite')}
          </Button>
        </div>
      </ResponsePageWrapper>
      <PrimaryEmailModal
        open={modalOpen}
        setOpen={setModalOpen}
        onClick={handleSubmit}
        emails={profileFields.filter((x) => x.dataType === ProfileDataType.EMAIL)}
      />
    </>
  );
}
