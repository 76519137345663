import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { AiOutlineUser } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { ProfileField } from '../../types/profile';
import { profileFieldToString } from '../../utils/stringUtils';
import { profileDataTypeIcons } from '../../utils/iconUtils';
import { authenticationService } from '../../services';
import useAuth from '../../hooks/useAuth';
import { isEmptyField } from '../../utils/validationUtils';
import ModalWrapper from '../layouts/ModalWrapper';
import Button from '../misc/Button';

interface ContactConfirmationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  info: { alias: string; receivedData: ProfileField[] } | undefined;
  inviterName: string | undefined;
}

export default function ContactConfirmationModal({
  open,
  setOpen,
  info,
  inviterName,
}: ContactConfirmationModalProps): JSX.Element {
  const auth = useAuth();
  const { t } = useTranslation();
  const fields = info?.receivedData.filter((f) => !isEmptyField(f)) || [];

  const focusRef = useRef(null);

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      className="bg-secondary-50 flex gap-4 flex-col min-w-[450px] w-fit pb-4 pt-5 mx-6 max-w-[600px] z-50 rounded-3xl p-8 h-a">
      <input className="invisible hidden" ref={focusRef} />
      <div className="flex flex-col gap-2">
        <h2 className="font-medium text-2xl">{t('modal.localContactConfirmation.title')}</h2>
        <p className="text-sm">
          {t('modal.localContactConfirmation.description', {
            authName: auth?.alias.replace('+', ' '),
            authEmail: auth?.email,
            inviterName,
          })}
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <div className="bg-secondary text-secondary-50 rounded-[6px] flex items-center justify-center p-1">
            <AiOutlineUser className="h-5 w-5" strokeWidth="2" />
          </div>
          <p className="my-auto">{info?.alias.replace('+', ' ') || ''}</p>
        </div>
        {fields.map((f) => (
          <div className="flex gap-2" key={f.dataType}>
            <div className="bg-secondary text-secondary-50 rounded-[6px] flex items-center justify-center p-1">
              {profileDataTypeIcons[f.dataType]}
            </div>
            <p className="my-auto">{profileFieldToString(f)}</p>
          </div>
        ))}
      </div>

      <div className="flex gap-2 mt-2 justify-end">

        <Button
          className="underline"
          onClick={() => {
            setOpen(false);
            authenticationService.logOut();
          }}>
          {t('general.logOut')}
        </Button>
        <Button variant="primary" className='px-[32px]' onClick={() => setOpen(false)}>
          {t('general.continue')}
        </Button>
      </div>
    </ModalWrapper>
  );
}
