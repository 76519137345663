/* eslint-disable no-useless-return */
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ProfileDataType, ProfileField } from '../../types/profile';
import {
  BusinessInvite,
  BusinessVersion,
  CustomFieldResponses,
  CustomFieldType,
} from '../../types/invites';
import { connectionService, invitationService } from '../../services';
import CustomFieldsForm from '../../components/forms/CustomFieldsForm';
import UserDataForm from '../../components/forms/userData/UserDataForm';
import { checkMandatoryFields } from '../../utils/validationUtils';
import useProfileFields from '../../hooks/useProfileFields';
import ResponsePageWrapper from '../../components/layouts/ResponsePageWrapper';
import InviteHeader from '../../components/headers/InviteHeader';
import useDeleteParams from '../../hooks/useDeleteParams';
import { SuccessPageState } from '../../types/navigation';
import { createEmptyCustomFieldResponses } from '../../utils/formatUtils';
import CommunicationNameInput from '../../components/forms/profile/CommunicationNameInput';
import { filterCustomLabelFields } from '../../utils/filterUtils';
import NoDataSharedModal from '../../components/modals/NoDataSharedModal';
import Button from '../../components/misc/Button';
import { SharedBusinessData } from '../../types/misc';
import NoRequestedDataCommunityInvitePage from '../reusable/NoRequestedDataCommunityInvitePage';

export default function AuthenticatedBusinessInvitePage() {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const profileFields = useProfileFields();
  useDeleteParams('link');

  const invitationId = params.get('invitationId');
  const type = params.get('type') as BusinessVersion | null;

  const [customFieldResponses, setCustomFieldResponses] = useState<CustomFieldResponses>(
    createEmptyCustomFieldResponses([]),
  );
  const [selectedData, setSelectedData] = useState<ProfileField[]>([]);
  const [invite, setInvite] = useState<BusinessInvite>();
  const [validate, setValidate] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!invitationId || !type) {
      toast.error(t('toast.error.general.invalidInvite'));
      navigate('/');
      return;
    }

    invitationService
      .getBusinessInvite(invitationId, type)
      .then((inv) => {
        setInvite(inv);
        setCustomFieldResponses(createEmptyCustomFieldResponses(inv.customFields));
      })
      .catch(() => {
        toast.error(t('toast.error.general.inviteNotFound'));
        navigate('/');
      });
  }, []);

  useEffect(() => {
    if (!invite || profileFields.length === 0) return;

    if (invite.version === BusinessVersion.MOMENTS)
      connectionService
        .getSharedDataWithUser(invite.requesterId)
        .then((data) => {
          setSelectedData(data);
        })
        .catch((): void => {});
    else
      connectionService
        .getSharedDataWithBusiness(invite.requesterId)
        .then((): void => {
          navigate('/success', {
            state: {
              translationKey: 'page.common.success.message.existingConnection',
              translationOptions: {
                requesterName: invite.requesterAlias,
              },
              businessInvite: invite,
            },
          });
        })
        .catch((): void => {});
  }, [invite, profileFields]);

  const handleComplete = (): void => {
    if (selectedData.length === 0) setModalOpen(true);
    else handleSubmit();
  };

  const handleSubmit = async (): Promise<void> => {
    if (!invitationId || !invite) return;
    setValidate(true);
    if (!checkMandatoryFields(invite!, selectedData, customFieldResponses)) return;

    const args: SharedBusinessData = {
      propertyIds: selectedData.map((x): string => x.id!),
      customFieldResponses,
      dataForMembers: [],
    };

    await connectionService.createBusinessConnection(invitationId, args, invite);

    const state: SuccessPageState = {
      translationKey: `page.common.success.message.${
        invite.version === BusinessVersion.COMMUNITY ? 'businessCommunityInvite' : 'businessInvite'
      }`,
      translationOptions: {
        businessName: invite.requesterAlias.replace('+', ' '),
      },
      ...(invite?.version === BusinessVersion.COMMUNITY
        ? { businessInvite: invite! }
        : { requesterId: invite?.requesterId, requesterAlias: invite?.requesterAlias }),
    };
    if (invite.connectedMembers)
      navigate('/community/members', { state: { invite, success: state, replace: true } });
    else {
      navigate('/success', { state });
      toast.success(t('toast.success.connectionCreated'));
    }
  };

  if (invite && !invite.shareWithAdmins)
    return <NoRequestedDataCommunityInvitePage invite={invite!} onSubmit={handleSubmit} />;

  const requesterName = invite?.requesterAlias.split('+')[0] || '';
  const communicationName = invite?.customFields.find(
    (f) => f.type === CustomFieldType.COMMUNICATION_NAME,
  );

  return (
    <>
      <ResponsePageWrapper>
        <ResponsePageWrapper.Header>
          <InviteHeader requesterName={requesterName} message={invite?.message!} />
        </ResponsePageWrapper.Header>
        <div className="gap-4 flex flex-col flex-1 h-full">
          <div className="flex flex-col gap-2">
            <h2 className="font-medium text-lg">{t('page.shared.requestedData')}</h2>
            <p className="text-sm">{t('page.businessInvite.message', { name: requesterName })}</p>
          </div>

          {communicationName && (
            <div className=" pb-4 border-secondary-200 border-b">
              <h2 className="font-medium text-lg mb-1 border-secondary-200 border-t pt-4">
                {t('form.communicationName.label')} {communicationName?.mandatory && '*'}
              </h2>
              <CommunicationNameInput
                name={customFieldResponses[communicationName.customFieldId]}
                validate={validate}
                setName={(name) =>
                  setCustomFieldResponses({
                    ...customFieldResponses,
                    [communicationName.customFieldId]: name,
                  })
                }
                mandatory={communicationName.mandatory}
              />
            </div>
          )}

          <UserDataForm
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            mandatoryFields={invite?.mandatoryFields || []}
            nonRequestedFields={[
              ...(invite?.nonRequestedFields || []),
              ProfileDataType.MOMENT,
              ProfileDataType.BUSINESSNAME,
              ProfileDataType.SOCIAL,
            ]}
            onePerDataType={invite?.version === BusinessVersion.COMMUNITY}
            onlyBirthDate
          />

          <div>
            {invite?.customFields && filterCustomLabelFields(invite?.customFields).length > 0 && (
              <h2 className="font-medium text-lg mb-1 border-secondary-200 border-t pt-4">
                {t('page.shared.requestedFields')}
              </h2>
            )}
            <CustomFieldsForm
              fields={invite?.customFields || []}
              response={customFieldResponses}
              setResponse={setCustomFieldResponses}
            />
          </div>
          <div className="flex flex-1 h-full gap-2 justify-end items-end align-bottom min-h-10">
            <Button variant="primary" className="px-[32px]" onClick={handleComplete}>
              {t('page.shared.shareData')}
            </Button>
          </div>
        </div>
      </ResponsePageWrapper>
      <NoDataSharedModal
        open={modalOpen}
        setOpen={setModalOpen}
        requesterName={requesterName}
        handleComplete={handleSubmit}
      />
    </>
  );
}
