import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserStats } from '../../types/profile';
import { isMobile } from '../../utils/userAgentUtils';
import QuestionCircle from './QuestionCircle';

interface UserStatsDisplayProps {
  stats: UserStats | undefined;
}

export default function UserStatsDisplay({ stats }: UserStatsDisplayProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className="flex relative">
      <div className="flex items-center shrink-0 flex-col pr-4 lg:px-4 border-secondary-200 border-r">
        <p className="text-gray-500 text-xs">{t('page.common.success.stats.connections')}</p>
        <p className="text-gray-500 text-lg">{stats?.connections || 0}</p>
      </div>
      <div className="flex items-center shrink-0 flex-col px-4 border-secondary-200 border-r">
        <p className="text-gray-500 text-xs">{t('page.common.success.stats.communities')}</p>
        <p className="text-gray-500 text-lg">{stats?.communities || 0}</p>
      </div>
      <div className="flex items-center shrink-0  flex-col pl-4">
        <p className="text-gray-500 text-xs">{t('page.common.success.stats.actions')}</p>
        <p className="text-secondary text-lg">{stats?.notifications || 0}</p>
      </div>
      {!isMobile() && (
        <div className="-mt-[3px] ml-1 w-full">
          <QuestionCircle text={t('page.common.success.stats.explanation')} relative={false} position='right-32 top-6' />
        </div>
      )}
    </div>
  );
}
