import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Footer(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="bg-primary-900 rounded-t-[20px] w-full h-fit min-h-[64px] flex text-medium text-secondary-50 items-center px-4 py-2 desktop:px-0 md:py-0">
      <div className="flex md:flex-row flex-col justify-between max-w-7xl md:items-center md:mx-auto w-full">
        <p className='text-secondary-50'>{`Copyright ® ${new Date().getFullYear()} Vera Connect`}</p>
        <div className="flex w-fit mt-4 md:mt-0 md:gap-6 md:items-center md:flex-row flex-col">
          <Link to="/about">{t('footer.about')}</Link>
          <Link to="/privacy-statement">{t('footer.termsAndPrivacy')}</Link>
        </div>
      </div>
    </div>
  );
}
