import { toast } from 'react-toastify';
import { isValidPhoneNumber } from 'react-phone-number-input';
import validator from 'email-validator';
import { ValidProfileDataMap } from '../types/misc';
import { AddressGeneral, AddressUS, ProfileDataType, ProfileField } from '../types/profile';
import { BusinessInvite, CustomFieldResponses, CustomFieldType } from '../types/invites';
import { joinWithAnd, profileDataTypeToString } from './stringUtils';
import { filterCustomLabelFields } from './filterUtils';
import i18n from '../locales/i18n';

export function isValidEmail(email: string): boolean {
  /* eslint-disable no-useless-escape */
  return validator.validate(email);
}

export function isEmptyEmail(field: ProfileField): boolean {
  return !field.email;
}

export function isEmptyPhoneNumber(field: ProfileField): boolean {
  return !field.phoneNumber?.prefix && !field.phoneNumber?.suffix;
}

export function isEmptyAddress(field: ProfileField): boolean {
  const { address } = field;
  if (!address) return true;
  if (address.countryCode === 'US') {
    const addressUS = address as AddressUS;
    return (
      !addressUS.streetLine &&
      !addressUS.streetLineTwo &&
      !addressUS.zipCode &&
      !addressUS.city &&
      !addressUS.state &&
      !addressUS.country &&
      !addressUS.countryCode
    );
  }
  const addressGeneral = address as AddressGeneral;
  return (
    !addressGeneral.street &&
    !addressGeneral.houseNumber &&
    !addressGeneral.houseNumberAddition &&
    !addressGeneral.city &&
    !addressGeneral.postCode &&
    !addressGeneral.country &&
    !addressGeneral.countryCode
  );
}

export function isEmptyBirthDate(field: ProfileField): boolean {
  return !field.birthDate || field.birthDate === '--';
}

export function isEmptyField(field: ProfileField): boolean {
  switch (field.dataType) {
    case 'EMAIL':
      return isEmptyEmail(field);
    case 'PHONENUMBER':
      return isEmptyPhoneNumber(field);
    case 'ADDRESS':
      return isEmptyAddress(field);
    case 'BIRTHDATE':
      return isEmptyBirthDate(field);
    default:
      return false;
  }
}

export function isValidProfileFields(fields: ProfileField[]): boolean {
  const invalid = fields
    .filter((field): boolean => !IsValidField(field))
    .map((field): string => field.dataType);
  if (invalid.length === 0) return true;
  if (invalid.includes(ProfileDataType.EMAIL)) {
    toast.error(i18n.t('toast.error.field.email'));
    return false;
  }
  if (invalid.includes(ProfileDataType.PHONENUMBER)) {
    toast.error(i18n.t('toast.error.field.phoneNumber'));
    return false;
  }
  if (invalid.includes(ProfileDataType.ADDRESS)) {
    toast.error(i18n.t('toast.error.field.address'));
    return false;
  }
  if (invalid.includes(ProfileDataType.BIRTHDATE)) {
    toast.error(i18n.t('toast.error.field.birthDate'));
    return false;
  }
  return true;
}

export function checkMandatoryFields(
  invite: BusinessInvite,
  selectedData: ProfileField[],
  customFieldResponses: CustomFieldResponses,
): boolean {
  const mandatoryFields = invite?.mandatoryFields || [];
  const missingFields = mandatoryFields.filter(
    (x): boolean =>
      !selectedData.map((y): ProfileDataType => y.dataType).includes(x) ||
      selectedData
        .filter(isEmptyField)
        .map((y): ProfileDataType => y.dataType)
        .includes(x),
  );

  if (missingFields.length > 0) {
    toast.error(
      i18n.t('toast.error.field.mandatory', {
        fields: joinWithAnd(missingFields.map(profileDataTypeToString)),
        count: missingFields.length,
      }),
    );
    return false;
  }

  const missingCustomFields =
    filterCustomLabelFields(invite?.customFields!).filter(
      (cf): boolean => cf.mandatory && !customFieldResponses[cf.customFieldId],
    ) || [];
  if (missingCustomFields?.length > 0) {
    toast.error(i18n.t('toast.error.field.mandatoryStar'));
    return false;
  }

  const communicationName = invite?.customFields!.find(
    (cf) => cf.type === CustomFieldType.COMMUNICATION_NAME,
  );

  if (communicationName?.mandatory && !customFieldResponses[communicationName.customFieldId]) {
    toast.error(i18n.t('toast.error.field.communicationName'));
    return false;
  }
  return true;
}

export function IsValidEmailField(field: ProfileField, canBeEmpty: boolean): boolean {
  return (canBeEmpty && isEmptyEmail(field)) || isValidEmail(field.email!);
}

export function IsValidPhoneNumberField(field: ProfileField, canBeEmpty: boolean): boolean {
  return (
    (canBeEmpty && isEmptyPhoneNumber(field)) ||
    isValidPhoneNumber(`${field.phoneNumber?.prefix}${field.phoneNumber?.suffix}`)
  );
}

export function IsValidAddressField(field: ProfileField, canBeEmpty: boolean): boolean {
  if (canBeEmpty && isEmptyAddress(field)) return true;
  const { address } = field;
  if (!address) return false;
  if (address.countryCode === 'US') {
    const addressUS = address as AddressUS;
    return (
      !!addressUS.streetLine &&
      !!addressUS.zipCode &&
      !!addressUS.city &&
      !!addressUS.state &&
      !!addressUS.country &&
      !!addressUS.countryCode
    );
  }
  const addressGeneral = address as AddressGeneral;
  return (
    !!addressGeneral.street &&
    !!addressGeneral.houseNumber &&
    !!addressGeneral.city &&
    !!addressGeneral.postCode &&
    !!addressGeneral.country &&
    !!addressGeneral.countryCode
  );
}

export function IsValidBirthDateField(field: ProfileField, canBeEmpty: boolean): boolean {
  const [year, month, day] = field.birthDate?.split('-') || [];
  if (!year && !month && !day) return canBeEmpty;
  if (Number.isNaN(+year) || Number.isNaN(+month) || Number.isNaN(+day)) return false;
  if (!year || !month || !day) return false;
  if (+month < 1 || +month > 12) return false;
  if (+day < 1 || +day > 31) return false;
  const currentDate = new Date();
  const selectedDate = new Date(+year, +month - 1, +day);
  currentDate.setHours(0, 0, 0, 0);

  if (selectedDate > currentDate) return false;
  return true;
}

export function IsValidField(field: ProfileField, canBeEmpty: boolean = true): boolean {
  switch (field.dataType) {
    case 'EMAIL':
      return IsValidEmailField(field, canBeEmpty);
    case 'PHONENUMBER':
      return IsValidPhoneNumberField(field, canBeEmpty);
    case 'ADDRESS':
      return IsValidAddressField(field, canBeEmpty);
    case 'BIRTHDATE':
      return IsValidBirthDateField(field, canBeEmpty);
    case 'BUSINESSNAME':
      return true;
    default:
      return false;
  }
}
