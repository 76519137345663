import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useDelayedEffect from '../../../hooks/useDelayedEffect';

interface CommunicationNameInputProps {
  name: string;
  setName: (name: string) => void;
  mandatory?: boolean;
  label?: boolean;
  validate?: boolean;
}

export default function CommunicationNameInput({
  name,
  setName,
  mandatory = false,
  label = false,
  validate = false,
}: CommunicationNameInputProps): JSX.Element {
  const { t } = useTranslation();
  const [error, setError] = useState<string>('');

  useDelayedEffect(() => {
    if (!validate) return;
    if (!name) {
      setError(!mandatory ? '' : t('invalid.empty'));
      return
    }
    setError('');
  }, [name, validate]);

  return (
    <div>
      {label && (
        <label className=" pr-2 font-medium text-base mb-1">
          {t('form.communicationName.label')} {mandatory ? '*' : ''}
        </label>
      )}
      <div className="flex flex-col w-full relative">
        <label className="pr-2 mb-1 text-sm focus:ring-0 focus:ring-transparent focus:border-[#01AFA5] focus:border-3">
          {t('form.communicationName.communicationName')}
        </label>
        <input
          placeholder={t('form.communicationName.placeholder')}
          className={classNames(
            'px-2 border rounded-lg h-10 text-gray-600 w-full focus:ring-0 focus:ring-transparent ',
            {
              '!border-red-500': error,
            },
          )}
          name="email"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <p className="text-red-500 absolute top-0 right-1 text-sm transition-all">{error}</p>
      </div>
    </div>
  );
}
