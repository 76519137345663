/* eslint-disable default-case */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useEffectAfterFirstRender from '../../../hooks/useEffectAfterFirstRender';
import { Address, AddressGeneral, ProfileDataType, ProfileField } from '../../../types/profile';
import { createEmptyField } from '../../../utils/formatUtils';
import httpService from '../../../services/httpService';
import useDelayedEffect from '../../../hooks/useDelayedEffect';
import { IsValidField, isEmptyField } from '../../../utils/validationUtils';
import {
  COLOR_PRIMARY,
  COLOR_PRIMARY_300,
  COLOR_PRIMARY_900,
  COLOR_SECONDARY_50,
} from '../../../constants';

interface AddressInputProps {
  field: ProfileField;
  setField: (field: ProfileField) => void;
  canBeEmpty?: boolean;
  mandatory?: boolean;
  label?: boolean;
  validate?: boolean;
}

type SelectOption = {
  label: string;
  value: string;
} | null;

interface AddressFetchParams {
  postCode: string;
  houseNumber: string;
  countryCode: string;
  houseNumberAddition?: string;
}

export default function AddressInput({
  field,
  setField,
  canBeEmpty = false,
  mandatory = false,
  label = false,
  validate = false,
}: AddressInputProps): JSX.Element {
  const [value, setValue] = useState<SelectOption>();
  const [address, setAddress] = useState<AddressGeneral>();
  const [addressString, setAddressString] = useState<string>('');
  const [error, setError] = useState<string>('');
  const options = useMemo(() => countryList().getData(), []);

  useDelayedEffect(() => {
    if (!validate) return;
    if (isEmptyField(field)) {
      setError(canBeEmpty ? '' : t('invalid.empty'));
      return;
    }
    if (!IsValidField(field)) {
      setError(t('invalid.invalid'));
      return;
    }

    setError('');
  }, [field.address, validate]);

  const { t } = useTranslation();

  const changeHandler = (v: SelectOption) => {
    setValue(v);
  };

  const fetchAddress = async () => {
    if ((value?.value! === 'NL' || !value?.value) && address?.postCode && address?.houseNumber) {
      let params: AddressFetchParams = {
        postCode: address.postCode,
        houseNumber: address.houseNumber,
        countryCode: 'NL',
      };
      if (address.houseNumberAddition)
        params = { ...params, houseNumberAddition: address.houseNumberAddition };

      const { data } = await httpService.get('/address', { params }).catch(() => {
        setAddressString('');
        return { data: undefined };
      });

      if (!data) return;
      setField({
        ...field,
        address: data.address,
      });
      setAddressString(data.display);
    }
  };

  useDelayedEffect(fetchAddress, [address, value]);

  const addressEmpty = () => {
    if (!address) return true;
    return field.address?.countryCode === 'NL'
      ? address?.postCode === '' && address?.houseNumber === ''
      : address?.city === '' &&
          address?.street === '' &&
          address?.houseNumber === '' &&
          address?.houseNumberAddition === '' &&
          address?.postCode === '';
  };

  useEffect(() => {
    if (!addressEmpty()) {
      setField({
        ...field,
        address: {
          ...address,
          countryCode: value?.value || field?.address?.countryCode,
          country: value?.label || field?.address?.country,
        } as Address,
      });
      setAddressString('');
    }
  }, [address]);

  useEffectAfterFirstRender(() => {
    if (addressEmpty()) {
      const addressField = field.address;
      setValue(
        addressField?.country && addressField?.countryCode
          ? {
              label: addressField.country,
              value: addressField.countryCode,
            }
          : value,
      );

      setField({ ...field, address: createEmptyField(ProfileDataType.ADDRESS).address });
    }
  }, [address]);

  const runRef = useRef<boolean>(false); //needed to know whether form has been typed in before
  useEffect(() => {
    // needed for setting the value if passed a field with a country code
    if (!value && field.address?.countryCode) {
      setValue({
        label: countryList().getLabel(field.address.countryCode),
        value: field.address.countryCode,
      });
    }

    // needed for defaulting to NL on first render but not subsequent ones
    if (runRef.current) return;
    if (isEmptyField(field)) {
      setValue({ label: 'Netherlands', value: 'NL' });
    } else runRef.current = true;
  }, [field]);

  useEffect(() => {
    if (!value?.label || !value?.value) return;
    if (
      field.address?.country !== value?.label &&
      field.address?.countryCode !== value?.value &&
      !isEmptyField(field)
    )
      setField({
        ...field, // @ts-ignore
        address: { ...field.address!, country: value?.label, countryCode: value?.value },
      });
  }, [value]);

  const renderInput = () => {
    const addressGeneral = field.address as AddressGeneral;
    switch (value?.value || addressGeneral.countryCode) {
      case 'NL':
        return (
          <div className="flex flex-col ">
            <div className="flex flex-row w-full mt-2 ">
              <div className="flex flex-col w-4/6">
                <label htmlFor={field.id} className="pr-2 mb-1  text-sm">
                  {t('form.address.postalCode')}
                </label>
                <input
                  id={field.id}
                  type="text"
                  name="postal-code"
                  placeholder={t('form.address.postalCode')}
                  value={addressGeneral.postCode ?? ''}
                  onChange={(e) =>
                    setAddress({
                      ...addressGeneral!,
                      postCode: e.target.value.replaceAll(' ', '').toUpperCase(),
                    })
                  }
                  data-testid={`street-input-${field.id}`}
                  className={classNames(
                    'px-2 border border-gray-400 rounded-lg mr-1 h-10 text-gray-600 focus:ring-0 focus:ring-transparent',
                    {
                      '!border-red-500': error,
                    },
                  )}
                />
              </div>
              <div className="flex flex-col w-1/6">
                <label htmlFor={field.id} className="pr-2 mb-1  text-sm">
                  {t('form.address.houseNumber')}
                </label>
                <input
                  id={field.id}
                  type="text"
                  placeholder={t('form.address.houseNumber')}
                  value={addressGeneral.houseNumber ?? ''}
                  onChange={(e) => setAddress({ ...addressGeneral!, houseNumber: e.target.value })}
                  data-testid={`number-input-${field.id}`}
                  className={classNames(
                    'px-2 border border-gray-400 rounded-lg mr-1 h-10 text-gray-600 focus:ring-0 focus:ring-transparent',
                    {
                      '!border-red-500': error,
                    },
                  )}
                />
              </div>
              <div className="flex flex-col w-1/6">
                <label htmlFor={field.id} className="pr-2 mb-1 text-sm">
                  {t('form.address.houseNumberAddition')}
                </label>
                <input
                  id={field.id}
                  type="text"
                  placeholder={t('form.address.houseNumberAddition')}
                  value={addressGeneral.houseNumberAddition ?? ''}
                  onChange={(e) =>
                    setAddress({ ...addressGeneral!, houseNumberAddition: e.target.value })
                  }
                  data-testid={`houseNumberAddition-input-${field.id}`}
                  className={classNames(
                    'px-2 border border-gray-400 rounded-lg mr-1 h-10 text-gray-600 focus:ring-0 focus:ring-transparent focus:border-[#01AFA5] focus:border-3',
                    {
                      '!border-red-500': error,
                    },
                  )}
                />
              </div>
            </div>
            <p className={`mt-2 text-sm mx-auto ${!addressString && 'hidden'}`}>{addressString}</p>
          </div>
        );
      default:
        return (
          <>
            {' '}
            <div className="flex flex-row gap-2 w-full my-1">
              <div className="flex flex-col w-4/6">
                <label htmlFor={field.id} className="pr-2 mb-1 text-sm">
                  {t('form.address.street')}
                </label>
                <input
                  id={field.id}
                  type="text"
                  name="street-address"
                  placeholder={t('form.address.street')}
                  data-testid={`street-input-${field.id}`}
                  value={addressGeneral.street}
                  className={classNames(
                    'px-2 border border-gray-400 rounded-lg mr-1 h-10 text-gray-600 focus:ring-0 focus:ring-transparent focus:border-[#01AFA5] focus:border-3',
                    {
                      '!border-red-500': error,
                    },
                  )}
                  onChange={(e) => setAddress({ ...addressGeneral!, street: e.target.value })}
                />
              </div>
              <div className="flex flex-col w-1/6">
                <label htmlFor={field.id} className="pr-2 mb-1 text-sm">
                  {t('form.address.houseNumber')}
                </label>
                <input
                  id={field.id}
                  name="street-address"
                  type="text"
                  placeholder={t('form.address.houseNumber')}
                  data-testid={`number-input-${field.id}`}
                  value={addressGeneral.houseNumber}
                  className={classNames(
                    'px-2 border border-gray-400 rounded-lg mr-1 h-10 text-gray-600 focus:ring-0 focus:ring-transparent focus:border-[#01AFA5] focus:border-3',
                    {
                      '!border-red-500': error,
                    },
                  )}
                  onChange={(e) => setAddress({ ...addressGeneral!, houseNumber: e.target.value })}
                />
              </div>
              <div className="flex flex-col w-1/6">
                <label htmlFor={field.id} className="pr-2 mb-1 text-sm">
                  {t('form.address.houseNumberAddition')}
                </label>
                <input
                  id={field.id}
                  type="text"
                  placeholder={t('form.address.houseNumberAddition')}
                  data-testid={`houseNumberAddition-input-${field.id}`}
                  value={addressGeneral.houseNumberAddition}
                  className={classNames(
                    'px-2 border border-gray-400 rounded-lg mr-1 h-10 text-gray-600 focus:ring-0 focus:ring-transparent focus:border-[#01AFA5] focus:border-3',
                    {
                      '!border-red-500': error,
                    },
                  )}
                  onChange={(e) =>
                    setAddress({ ...addressGeneral!, houseNumberAddition: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="flex flex-row gap-2 mt-1">
              <div className="flex flex-col w-1/3">
                <label htmlFor={field.id} className="pr-2 mb-1 text-sm">
                  {t('form.address.postalCode')}
                </label>
                <input
                  id={field.id}
                  name="street-address"
                  type="text"
                  placeholder={t('form.address.postalCode')}
                  data-testid={`postCode-input-${field.id}`}
                  value={addressGeneral.postCode}
                  className={classNames(
                    'px-2 border border-gray-400 rounded-lg mr-1 h-10 text-gray-600 focus:ring-0 focus:ring-transparent focus:border-[#01AFA5] focus:border-3',

                    {
                      '!border-red-500': error,
                    },
                  )}
                  onChange={(e) =>
                    setAddress({
                      ...addressGeneral!,
                      postCode: e.target.value.replaceAll(' ', '').toUpperCase(),
                    })
                  }
                />
              </div>
              <div className="flex flex-col w-2/3">
                <label htmlFor={field.id} className="pr-2 mb-1 text-sm">
                  {t('form.address.city')}
                </label>
                <input
                  id={field.id}
                  name="street-address"
                  type="text"
                  placeholder={t('form.address.city')}
                  data-testid={`city-input-${field.id}`}
                  value={addressGeneral.city}
                  className={classNames(
                    'px-2 border border-gray-400 rounded-lg mr-1 h-10 text-gray-600 focus:ring-0 focus:ring-transparent focus:border-[#01AFA5] focus:border-3',
                    {
                      '!border-red-500': error,
                    },
                  )}
                  onChange={(e) => setAddress({ ...addressGeneral!, city: e.target.value })}
                />
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <div className="w-full">
      {label && (
        <label className=" pr-2 font-medium text-base mb-1">
          {t('form.address.label')} {mandatory ? '*' : ''}
        </label>
      )}

      <div className="flex flex-col w-full relative">
        <div className="flex flex-col">
          <label htmlFor={field.id} className="mb-1 pr-2  text-sm">
            {t('form.address.country')}
          </label>
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: !error
                  ? state.isFocused
                    ? COLOR_PRIMARY_300
                    : COLOR_PRIMARY
                  : '#EF4444',
                borderRadius: '9999px',
                color: COLOR_PRIMARY_900,
                backgroundColor: COLOR_SECONDARY_50,
                boxShadow: 'none',
                height: '40px',
                '&:hover': {
                  borderColor: !error
                    ? state.isFocused
                      ? COLOR_PRIMARY_300
                      : COLOR_PRIMARY
                    : '#EF4444',
                },
              }),
              option: (styles, { isSelected }) => {
                return {
                  ...styles,
                  backgroundColor: isSelected ? COLOR_PRIMARY : COLOR_SECONDARY_50,
                  color: isSelected ? COLOR_SECONDARY_50 : COLOR_PRIMARY_900,
                  '&:hover': {
                    backgroundColor: isSelected ? COLOR_PRIMARY_300 : COLOR_PRIMARY_300,
                    color: COLOR_PRIMARY_900,
                  },
                };
              },
              indicatorSeparator: () => ({
                display: 'none',
              }),
              dropdownIndicator: (baseStyles, state) => ({
                ...baseStyles,
                color: state.isFocused ? COLOR_PRIMARY_300 : COLOR_PRIMARY,
              }),
            }}
            options={options as any}
            placeholder={t('form.address.countryPlaceholder')}
            value={{
              label: value?.label || field.address?.country || 'Netherlands',
              value: value?.value || field.address?.countryCode || 'NL',
            }}
            onChange={changeHandler}
            className="mb-1"
          />
        </div>
        {renderInput()}
        <p className="text-red-500 absolute top-0 right-1 text-sm transition-all">{error}</p>
      </div>
    </div>
  );
}
