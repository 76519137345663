import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ProfileField } from '../../../types/profile';
import { isValidEmail } from '../../../utils/validationUtils';
import { profileService } from '../../../services';
import useDelayedEffect from '../../../hooks/useDelayedEffect';

interface EmailInputProps {
  field: ProfileField;
  setField: (field: ProfileField) => void;
  canBeEmpty?: boolean;
  emailCheck?: boolean;
  setAccountExists?: (exists: boolean) => void;
  mandatory?: boolean;
  label?: boolean;
  validate?: boolean;
}

export default function EmailInput({
  field,
  setField,
  canBeEmpty = false,
  emailCheck = false,
  mandatory = false,
  label = false,
  validate = false,
  setAccountExists = () => {},
}: EmailInputProps): JSX.Element {
  const { t } = useTranslation();
  const [error, setError] = useState<string>('');

  useDelayedEffect(() => {
    if (!validate) return;
    if (!field.email) {
      setError(canBeEmpty ? '' : t('invalid.empty'));
      return;
    }
    setError(isValidEmail(field.email) ? '' : t('invalid.invalid'));
  }, [field.email, validate]);

  const onBlur = (): void => {
    if (!emailCheck || !field.email || !isValidEmail(field.email)) return;
    profileService.checkEmailExists(field.email)
        .then((exists): void => {
          setAccountExists(exists);
          setError('');
        })
        .catch(() => setError(t('invalid.invalid')));
  };

  return (
    <div className="w-full">
      {label && (
        <label className=" pr-2 font-medium text-base mb-1">
          {t('form.email.label')} {mandatory ? '*' : ''}
        </label>
      )}
      <div className="flex flex-col w-full relative">
        <label
          htmlFor={field.id}
          className="pr-2 mb-1 text-sm">
          {t('form.email.email')}
        </label>
        <input
          id={field.id}
          onBlur={onBlur}
          placeholder={t('form.email.email')}
          className={classNames(
            'px-2 border rounded-lg h-10 text-gray-600 w-full focus:ring-0 focus:ring-transparent ',
            {
              '!border-red-500': error,
            },
          )}
          name="email"
          data-testid={`email-input-${field.id}`}
          type="text"
          value={field.email ?? ''}
          onChange={(e) => setField({ ...field, email: e.target.value })}
        />
        <p className="text-red-500 absolute top-0 right-1 text-sm transition-all">{error}</p>

      </div>
    </div>
  );
}
