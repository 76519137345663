import i18n from '../locales/i18n';
import { AddressGeneral, AddressUS, ProfileDataType, ProfileField } from '../types/profile';

export function profileFieldToString(field: ProfileField): string {
  switch (field.dataType) {
    case ProfileDataType.EMAIL:
      return field.email!;

    case ProfileDataType.BUSINESSNAME:
      return field.description!;

    case ProfileDataType.PHONENUMBER:
      return `${field.phoneNumber?.prefix!} ${field.phoneNumber?.suffix!}`;

    case ProfileDataType.BIRTHDATE:
      if (!field.birthDate) return '';
      const [year, month, day] = field.birthDate.split('-');
      return `${day}-${month}-${year}`;

    case ProfileDataType.ADDRESS:
      const addressField = field.address;
      if (!addressField) return '';

      if (addressField.countryCode === 'US') {
        const address = addressField as AddressUS;
        return `${address.streetLine} ${address.streetLineTwo} ${address.zipCode}\n${address.city} ${address.state}\n${address.country}`;
      }
      const address = addressField as AddressGeneral;
      return `${address.street} ${address.houseNumber}${address.houseNumberAddition ?? ''}\n${
        address.postCode
      } ${address.city}\n${address.country}`;

    default:
      return '';
  }
}

export function profileDataTypeToString(type: ProfileDataType): string {
  switch (type) {
    case ProfileDataType.EMAIL:
      return i18n.t('dataType.email');
    case ProfileDataType.PHONENUMBER:
      return i18n.t('dataType.phoneNumber');
    case ProfileDataType.BIRTHDATE:
      return i18n.t('dataType.birthDate');
    case ProfileDataType.ADDRESS:
      return i18n.t('dataType.address');
    default:
      return '';
  }
}

export function joinWithAnd(strings: string[]): string {
  if (strings.length === 0) return '';
  if (strings.length === 1) return strings[0];
  return `${strings.slice(0, strings.length - 1).join(', ')} ${i18n.t('general.and')} ${
    strings[strings.length - 1]
  }`;
}

export function capitalizeFirstLetter(x: string) {
  const lowerCase = x.toLocaleLowerCase();
  return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
}

export function getInitials(alias: string): string {
  return alias
    .split('+')
    .map((x) => x.split(' '))
    .flat()
    .map((x) => x[0])
    .join('');
}
